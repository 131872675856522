<template>
    <div>
        <div v-if="preloading">
            <div class="d-flex justify-content-center align-items-center preloading">
                <div class="loader-xl"/>
            </div>
        </div>
        <div v-else>
            <div class="row justify-content-center mb-5">
                <DashboardKPI
                    :label="$t('redactor.nbWordsWriter')"
                    :number="redactor.word_count"
                    background="bg-primary"
                    icon-class="fa fa-quote-right"
                />
                <DashboardKPI
                    :label="$t('redactor.nbWordsWrittenToday')"
                    :number="redactor.written_words_today || 0"
                    background="bg-secondary"
                    icon-class="fa fa-sort-amount-down"
                />
                <DashboardKPI
                    :label="$t('redactor.preorders-processed')"
                    :number="preorders.length || 0"
                    background="bg-success"
                    icon-class="fa fa-lightbulb"
                />
            </div>

            <div class="orders mb-5 row">
                <div class="col-md-12 col-lg-12 col-xl-6">
                    <h2>{{ $t('redactor.orders-processed') }}</h2>
                    <p>{{ $t('global.hi') }} {{ redactor.user.firstname }},</p>

                    <div v-if="redactor.written_words_today < redactor.word_count">
                        <button
                            v-if="isAffectingWriting === false"
                            class="btn btn-info btn-lg"
                            @click.prevent="assignOrder"
                        >
                            {{ $t('redactor.start-writing') }}
                        </button>
                        <div v-else>
                            <button
                                v-if="!found"
                                class="btn btn-info d-flex align-items-center justify-content-center"
                                disabled="disabled"
                            >
                                <div class="custom-loader"/>
                                <span class="ml-3 font-italic"
                                >Nous recherchons une commande pour vous, veuillez patienter...</span
                                >
                            </button>
                            <button
                                v-if="found"
                                class="btn btn-info d-flex align-items-center justify-content-center"
                                disabled="disabled"
                            >
                                <div class="custom-loader"/>
                                <span class="ml-3 font-italic">Commande trouvée, vous allez être redirigé...</span>
                            </button>
                        </div>
                    </div>

                    <div v-else-if="redactor.actived_at" class="alert alert-warning p-2">
                        {{ $t('redactor.no-orders') }}.
                    </div>

                    <div v-else class="alert alert-warning p-2">{{ $t('redactor.redactor-desactivate') }}.</div>
                </div>

                <div v-if="redactor.preorders === true && preorders.length > 0" class="col-md-12 col-lg-12 col-xl-6">
                    <h3>Précommandes :</h3>
                    <AssignedPreordersTable/>
                </div>


                <div v-if="writings && writings.data.length > 0" class="col-md-12 col-lg-12 col-xl-12">
                    <hr>
                    <h3 class="mt-5 pb-1">Mes commandes à rédiger :</h3>
                    <nl-table
                        sortable
                        :per-page="10"
                        :columns.sync="columns"
                        :get-data="loadAsyncData"
                    >
                        <template #isPrio="{ props: { row } }">
                            <div>
                                <span v-if="row.isPrio">
                                    <i class="fas fa-flag prio" />
                                </span>
                                    <span v-else>
                                    <i class="far fa-flag" />
                                </span>
                            </div>
                        </template>

                        <template
                            #entity_id="{
                                props: {
                                    row: { entity_type, entity_id }
                                }
                            }">
                            <template>
                                <div
                                    :class="`tag ${entity_type === 1 ? 'is-warning' : entity_type === 2 ? 'is-info' : 'is-link'}`">
                                    #{{ entity_id }}
                                </div>
                            </template>
                        </template>

                        <template #created_at="{ props: { row } }">
                            {{ moment(row.created_at).format('L') }}
                        </template>

                        <template #created_atSearch="{ props }">
                            <b-input v-model="props.filters[props.column.field]" size="is-small" type="date"/>
                        </template>

                        <template #keyword="{ props: { row } }">
                            <div>
                                <p v-if="row.related_order != null">
                                    {{ row.related_order.keyword }}
                                </p>
                                <p v-else>-</p>
                                <span v-if="row.entity_type === 1">{{ row.page }}</span>
                                <span v-else>{{ row.site?.url }}</span>
                            </div>
                        </template>

                        <template #primary_link="{ props: { row } }">
                            <div v-if="row.entity_type === 1 || row.entity_type === 6">
                                <p>
                                    {{ $t('global.link') }} : <span>{{ row.primary_link }}</span>
                                </p>
                                <p>
                                    {{ $t('global.anchor') }} :
                                    <span class="text-muted">{{ row.primary_anchor }}</span>
                                </p>

                                <template v-if="row.secondary_link">
                                    <hr/>

                                    <p>
                                        {{ $t('global.link') }} 2 : <span>{{ row.secondary_link }}</span>
                                    </p>
                                    <p>
                                        {{ $t('global.anchor') }} 2 :
                                        <span class="text-muted">{{ row.secondary_anchor }}</span>
                                    </p>
                                </template>
                            </div>
                            <div v-else>
                                <p>-</p>
                            </div>
                        </template>

                        <template #words_count="{ props: { row } }">
                            {{ row.words_count }}
                        </template>


                        <template #lang="{ props: { row } }">
                            <p class="mb-0">
                                <flag :iso="row.lang === 'fr_fr' ? 'fr' : 'us'"/>
                            </p>
                        </template>

                        <template #desired_publication_date="{ props: { row } }">
                <span v-if="row.desired_publication_date">
                    {{ moment(row.desired_publication_date).fromNow() }}
                </span>
                            <span v-else>ASAP</span>
                        </template>

                        <template #desired_publication_dateSearch="{ props }">
                            <b-input v-model="props.filters[props.column.field]" size="is-small" type="date"/>
                        </template>

                    </nl-table>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import AssignedPreordersTable from '../../components/Redactor/Tables/AssignedPreordersTable'
import DashboardKPI from '@/components/UI/DashboardKPI.vue'
import NlTable from "@/components/UI/Table/NlTable.vue";
import LoginAs from "@/components/Admin/LoginAs.vue";
import NlWordpress from "@/components/UI/Wordpress/NlWordpress.vue";

export default {
    name: 'Redactor',
    title: 'Redactor',
    components: {NlWordpress, LoginAs, NlTable, DashboardKPI, AssignedPreordersTable},
    data: function () {
        return {
            //UI
            preloading: false,
            dataIsLoading: false,
            timeout: false,
            isAffectingWriting: false,
            found: false,
            columns: [
                {
                    custom: true,
                    field: 'isPrio',
                    label: 'Prio',
                    searchable: false
                },
                {
                    custom: true,
                    field: 'entity_id',
                    label: 'Ref',
                    searchable: false
                },
                {
                    custom: true,
                    field: 'created_at',
                    label: 'Créé le',
                    searchable: false
                },
                {
                    centered: false,
                    custom: true,
                    field: 'keyword',
                    label: 'Mot-clé acheté',
                    sortable: false,
                    searchable: false
                },
                {
                    centered: false,
                    custom: true,
                    field: 'primary_link',
                    label: 'Liens / ancres',
                    sortable: false,
                    searchable: false
                },
                {
                    custom: true,
                    field: 'words_count',
                    label: 'Nombre de mots',
                    visible: true,
                    searchable: false
                },
                {
                    custom: true,
                    field: 'lang',
                    label: 'Langue',
                    sortable: false,
                    searchable: false
                },
                {
                    field: 'brief',
                    label: 'Brief',
                    searchable: false
                },
                {
                    custom: true,
                    field: 'desired_publication_date',
                    label: 'Deadlines',
                    searchable: false
                }
            ],
        }
    },
    watch: {},
    computed: {
        ...mapState('redactors', {
            redactor: state => state.redactor,
            preorders: state => state.preorders,
            redactorWritring: state => state.redactorWritring,
            writings: state => state.writings,
        })
    },
    created() {
        this.getData()
    },
    methods: {
        ...mapActions('redactors', ['getRedactorInfo', 'askForWriting', 'getRedactorAssignedWritings']),
        getData: function () {
            this.preloading = true

            let writingsPromise = this.getRedactorAssignedWritings()
                .catch(error => {
                    this.$store.dispatch('toast', {
                        type: 'error',
                        title: 'Une erreur est survenue',
                        message: error.response.data.message
                    })
                })

            let redactorPromise = this.getRedactorInfo({
                id: this.$store.state.auth.user.id
            })
                .catch(error => {
                    this.$store.dispatch('toast', {
                        type: 'error',
                        title: 'Une erreur est survenue',
                        message: error.response.data.message
                    })
                })

            Promise.all([writingsPromise, redactorPromise])
                .finally(() => {
                    this.preloading = false
                })
        },

        loadAsyncData(params) {
            return this.getRedactorAssignedWritings(params)
        },

        assignOrder() {
            this.isAffectingWriting = true
            this.askForWriting(this.$store.state.auth.user.id)
                .then(data => {
                    this.found = true
                    window.location = `/redactor/writing?id=${data.writing}`
                })
                .catch(e => {
                    this.$store.dispatch('toast', {
                        type: 'error',
                        title: e.response.status !== 400 ? 'Une erreur est survenue' : '',
                        message: e.response.data.message
                    })
                    this.isAffectingWriting = false
                })
        }
    }
}
</script>
